
import { Component, Prop, Watch, Vue } from "vue-property-decorator";
import SearchBox from "@/components/SearchBox.vue";
import {
  TvNetworkVisibilityDTO,
  TVNetworkConfigDTO,
} from "@/data/dto/config.dto";

export type TvVisibilityStateType = {
  isDisplayed: boolean;
  isSelectedByDefault: boolean;
  isMainTvNetwork: boolean;
};

@Component({
  components: {
    SearchBox,
  },
})
export default class TvNetworkVisibilitySelection extends Vue {
  @Prop(Array) private collection!: TVNetworkConfigDTO[];
  @Prop({ default: "auto" }) private height!: string;
  @Prop() private value!: TvNetworkVisibilityDTO[];

  filteredCollection: TVNetworkConfigDTO[] = [];

  tvsToBeDisplayed: string[] = [];
  tvsSelectedByDefault: string[] = [];
  mainTvNetworks: string[] = [];

  get tvVisibilityList(): TvNetworkVisibilityDTO[] {
    return this.tvsToBeDisplayed.map((tv) => ({
      tvNetworkId: tv,
      selectedByDefault: this.tvsSelectedByDefault.includes(tv),
      isMainTvNetwork: this.mainTvNetworks.includes(tv),
    }));
  }
  get sortedCollection(): TVNetworkConfigDTO[] {
    return this.collection.sort((a) => this.tvsToBeDisplayed.includes(a.tvNetworkId) ? -1 : 1);
  }

  @Watch("tvVisibilityList")
  onTvVisibilityListChange(): void {
    this.$emit("input", this.tvVisibilityList);
  }

  @Watch("tvsToBeDisplayed")
  onTvsToBeDisplayedChange(newTvList: string[], oldTvList: string[]): void {
    if (newTvList.length < oldTvList.length) {
      this.tvsSelectedByDefault = this.tvsSelectedByDefault.filter((tv) => newTvList.includes(tv));
    } else {
      const addedTv = newTvList.find((tv) => !oldTvList.includes(tv));
      if (addedTv) this.tvsSelectedByDefault.push(addedTv);
    }
  }

  currentTvState(tv: string): TvVisibilityStateType {
    return {
      isDisplayed: this.tvsToBeDisplayed.includes(tv),
      isSelectedByDefault: this.tvsSelectedByDefault.includes(tv),
      isMainTvNetwork: this.mainTvNetworks.includes(tv),
    };
  }

  displayIcon(tv: string): string {
    const { isDisplayed } = this.currentTvState(tv);
    return isDisplayed ? "check-square" : "square";
  }

  selectionIcon(tv: string): string {
    const { isSelectedByDefault } = this.currentTvState(tv);
    return isSelectedByDefault ? "eye" : "eye-slash";
  }

  mainTvNetworkSelection(tv: string): string {
    const { isMainTvNetwork } = this.currentTvState(tv);
    return isMainTvNetwork ? "main-tv-networks-selected" : "main-tv-networks-not-selected";
  }

  onSearchFinished(result: TVNetworkConfigDTO[]): void {
    this.filteredCollection = result;
  }

  resetFilteredCollection(): void {
    this.filteredCollection = this.sortedCollection;
  }

  mounted(): void {
    this.tvsToBeDisplayed = this.value.map((tv) => tv.tvNetworkId);
    this.tvsSelectedByDefault = this.value
      .filter((tv) => !!tv.selectedByDefault)
      .map((tv) => tv.tvNetworkId);
    this.mainTvNetworks = this.value
      .filter((tv) => !!tv.isMainTvNetwork)
      .map((tv) => tv.tvNetworkId);
    this.resetFilteredCollection();
  }
}
