
import { Component, Mixins } from 'vue-property-decorator';
import FormValidationMixin, { IFormValidations } from '@/mixins/form-validation.mixin';
import { WeekdayConfigDTO } from '@/data/dto/config.dto';

@Component({})
export default class CreateWeekdaysGroupForm extends Mixins(FormValidationMixin) {  
  private defaultWeekdayGroup: WeekdayConfigDTO = {
    label: '',
    weekdays: [],
  };
  weekdayGroup = {...this.defaultWeekdayGroup};

  weekdaysLabels = {
    DOMINGO: 'DOM',
    SEGUNDA: 'SEG',
    TERCA: 'TER',
    QUARTA: 'QUA',
    QUINTA: 'QUI',
    SEXTA: 'SEX',
    SABADO: 'SÁB',
  };
  
  formValidations: IFormValidations = {
    label: () => !!this.weekdayGroup.label,
    weekdays: () => !!this.weekdayGroup.weekdays.length,
  };

  createWeekdayGroup(): void {
    if (!this.validateForm()) return;
    this.$emit('weekdayGroupCreated', this.weekdayGroup);
    this.weekdayGroup = {...this.defaultWeekdayGroup};
  }
}
