
import { Component, Vue } from 'vue-property-decorator';
import TabContentWrapper from '@/components/TabContentWrapper.vue';
import { MarketConfigDTO } from '@/data/dto/config.dto';
import draggable from 'vuedraggable';
import ModalFormHeader from '@/components/ModalFormHeader.vue';

@Component({
  components: {
    TabContentWrapper,
    draggable,
    ModalFormHeader,
  }
})
export default class MarketConfigs extends Vue {
  markets: MarketConfigDTO[] = [];
  modalIsVisible = false;
  currentMarket: MarketConfigDTO | null = null;
  currentLabel = '';

  async getMarketConfigs(): Promise<void> {
    try {
      const { markets } = await this.$store.dispatch('getMarketConfigs');
      this.markets = markets;
    } catch (e) {
      this.markets = [];
    }
  }

  get modalTitle(): string {
    const currentMarketId = this.currentMarket ? this.currentMarket.marketId : '';
    return `Edição de praça - ${currentMarketId}`;
  }

  async updateMarketConfigs(): Promise<void> {    
    try {
      await this.$store.dispatch('updateMarketConfigs', { markets: this.markets });
      this.$store.commit('showAlert', { message: 'Alterações realizadas com sucesso!', type: 'success'});
    } catch (e) { return; }
  }

  editMarket(market: MarketConfigDTO): void {
    this.currentMarket = market;
    this.currentLabel = market.exhibitionName;
    this.modalIsVisible = true;
  }

  confirmChanges(): void {
    if (this.currentMarket) this.currentMarket.exhibitionName = this.currentLabel;
    this.closeModal();
  }

  closeModal(): void {
    this.currentMarket = null;
    this.currentLabel = '';
    this.modalIsVisible = false;
  }

  mounted(): void {
    this.getMarketConfigs();
  }
}
