
import { Component, Mixins } from 'vue-property-decorator';
import TabContentWrapper from '@/components/TabContentWrapper.vue';
import { DailyAudienceConfigDTO } from '@/data/dto/config.dto';
import FormValidationMixin, { IFormValidations } from '@/mixins/form-validation.mixin';
import VUtil from '@/utils/validation.util';

@Component({
  components: {
    TabContentWrapper,
  }
})
export default class DailyAudienceConfigs extends Mixins(FormValidationMixin) {
  //default value
  defaultDailyAudienceConfig: DailyAudienceConfigDTO = { decimalPlaces: 0 };
  dailyAudienceConfig: DailyAudienceConfigDTO = {...this.defaultDailyAudienceConfig};

  //validations
  formValidations: IFormValidations = {
    decimalPlaces: this.checkDecimalPlaces,
  };
  
  checkDecimalPlaces(): boolean {
    return VUtil.isNumber(this.dailyAudienceConfig.decimalPlaces);
  }
  
  async getDailyAudienceConfigs(): Promise<void> {
    try {
      this.dailyAudienceConfig = await this.$store.dispatch('getDailyAudienceConfigs');
    } catch (e) {
      this.dailyAudienceConfig = {...this.defaultDailyAudienceConfig}; 
    }
  }

  async updateDailyAudienceConfigs(): Promise<void> {
    if (!this.validateForm()) return;    
    try {
      await this.$store.dispatch('updateDailyAudienceConfigs', this.dailyAudienceConfig);
      this.$store.commit('showAlert', { message: 'Alterações realizadas com sucesso!', type: 'success'});
    } catch (e) { return; }
  }

  mounted(): void {
    this.getDailyAudienceConfigs();
  }
}
