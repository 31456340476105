
import { Component, Mixins } from 'vue-property-decorator';
import TabContentWrapper from '@/components/TabContentWrapper.vue';
import { DailyGridConfigDTO } from '@/data/dto/config.dto';
import CreateTimeRangeForm from '@/components/CreateTimeRangeForm.vue';
import { TimeRange } from '@/data/dto/time-range.dto';
import PillsList from '@/components/PillsList.vue';
import FormValidationMixin, { IFormValidations } from '@/mixins/form-validation.mixin';
import VUtil from '@/utils/validation.util';

@Component({
  components: {
    TabContentWrapper,
    CreateTimeRangeForm,
    PillsList,
  }
})
export default class DailyGridConfigs extends Mixins(FormValidationMixin) {
  //default value
  defaultDailyGridConfigs: DailyGridConfigDTO = {
    decimalPlaces: 1,
    useTimeRange: true,
    timeRanges: [],
  };
  dailyGridConfigs: DailyGridConfigDTO = {...this.defaultDailyGridConfigs};

  //validations
  formValidations: IFormValidations = {
    decimalPlaces: this.checkDecimalPlaces,
  };
  
  checkDecimalPlaces(): boolean {
    return VUtil.isNumber(this.dailyGridConfigs.decimalPlaces);
  }

  onTimeRangeCreated(timeRange: TimeRange): void {
    this.dailyGridConfigs.timeRanges.push(timeRange);
  }
  
  generateTimeRangeLabel(item: TimeRange): string {
    return `${item.start.slice(0,5)} - ${item.end.slice(0,5)}`;
  }

  async getDailyGridConfigs(): Promise<void> {
    try {
      this.dailyGridConfigs = await this.$store.dispatch('getDailyGridConfigs');
    } catch (e) {
      this.dailyGridConfigs = {...this.defaultDailyGridConfigs}; 
    }
  }

  async updateDailyGridConfigs(): Promise<void> {
    if (!this.validateForm()) return;    
    try {
      await this.$store.dispatch('updateDailyGridConfigs', this.dailyGridConfigs);
      this.$store.commit('showAlert', { message: 'Alterações realizadas com sucesso!', type: 'success'});
    } catch (e) { return; }
  }

  mounted(): void {
    this.getDailyGridConfigs();
  }
    
}
