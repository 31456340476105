
import { Component, Mixins } from 'vue-property-decorator';
import TabContentWrapper from '@/components/TabContentWrapper.vue';
import { ConsolidatedConfigDTO, MarketConfigDTO, MarketGroup } from '@/data/dto/config.dto';
import FormValidationMixin, { IFormValidations } from '@/mixins/form-validation.mixin';
import VUtil from '@/utils/validation.util';
import CreateTimeRangeForm from '@/components/CreateTimeRangeForm.vue';
import { TimeRange } from '@/data/dto/time-range.dto';
import DraggableTwoColumnsList from '@/components/DraggableTwoColumnsList.vue';
import PillsList from '@/components/PillsList.vue';
import CreateMarketGroupForm from '@/components/CreateMarketGroupForm.vue';

@Component({
  components: {
    TabContentWrapper,
    CreateTimeRangeForm,
    DraggableTwoColumnsList,
    PillsList,
    CreateMarketGroupForm,
  }
})
export default class ConsolidatedConfigs extends Mixins(FormValidationMixin) {
  //default value
  private defaultConsolidatedConfigs: ConsolidatedConfigDTO = {
    decimalPlaces: 1,
    timeSlot: [],
    marketGroups: [],
  };
  consolidatedConfigs = {...this.defaultConsolidatedConfigs};

  allMarkets: string[] = [];
  currentGroup: MarketGroup | null = null;

  //validations
  formValidations: IFormValidations = {
    decimalPlaces: this.checkDecimalPlaces,
  };
  
  checkDecimalPlaces(): boolean {
    return VUtil.isNumber(this.consolidatedConfigs.decimalPlaces);
  }

  onTimeRangeCreated(timeRange: TimeRange): void {
    this.consolidatedConfigs.timeSlot.push(timeRange);
  }

  onMarketGroupCreated(group: MarketGroup): void {
    this.consolidatedConfigs.marketGroups.push(group);
  }

  generateTimeRangeLabel(item: TimeRange): string {
    return `${item.label}  ${item.start.slice(0,5)} - ${item.end.slice(0,5)}`;
  }

  async setCurrentGroup(payload: {item: MarketGroup, index: number}): Promise<void> {
    console.log('payload', payload.item)
    this.currentGroup = payload.item;
  }

  async getMarkets(): Promise<void> {
    try {
      const { markets } = await this.$store.dispatch('getMarketConfigs');
      this.allMarkets = markets.map((m: MarketConfigDTO) => m.marketId);
    } catch (e) {
      this.allMarkets = [];
    }
  }
  
  async getConsolidatedConfigs(): Promise<void> {
    try {
      this.consolidatedConfigs = await this.$store.dispatch('getConsolidatedConfigs');
    } catch (e) {
      this.consolidatedConfigs = {...this.defaultConsolidatedConfigs}; 
    }
  }

  async updateConsolidatedConfigs(): Promise<void> {
    if (!this.validateForm()) return;    
    try {
      await this.$store.dispatch('updateConsolidatedConfigs', this.consolidatedConfigs);
      this.$store.commit('showAlert', { message: 'Alterações realizadas com sucesso!', type: 'success'});
    } catch (e) { return; }
  }

  mounted(): void {
    this.getMarkets();
    this.getConsolidatedConfigs();
  }
}
