
import { Component, Mixins } from "vue-property-decorator";
import TabContentWrapper from "@/components/TabContentWrapper.vue";
import { RankingConfigDTO, TVNetworkConfigDTO } from "@/data/dto/config.dto";
import CreateTimeRangeForm from "@/components/CreateTimeRangeForm.vue";
import { TimeRange } from "@/data/dto/time-range.dto";
import PillsList from "@/components/PillsList.vue";
import CustomGroupedCheckboxList from "@/components/CustomGroupedCheckboxList.vue";
import FormValidationMixin, {
  IFormValidations,
} from "@/mixins/form-validation.mixin";
import VUtil from "@/utils/validation.util";
import moment from "moment";
import { sortTvNetworks } from "@/utils/tv-network.util";

@Component({
  components: {
    TabContentWrapper,
    CreateTimeRangeForm,
    PillsList,
    CustomGroupedCheckboxList,
  },
})
export default class RankingConfigs extends Mixins(FormValidationMixin) {
  rankingConfigs: RankingConfigDTO = {
    decimalPlaces: 0,
    timeRanges: [],
    hiddenTvNetworkIdList: [],
  } as RankingConfigDTO;

  listOfAvailableTvNetworks: TVNetworkConfigDTO[] = [];

  formValidations: IFormValidations = {
    decimalPlaces: this.checkDecimalPlaces,
  };

  checkDecimalPlaces(): boolean {
    return VUtil.isNumber(this.rankingConfigs.decimalPlaces);
  }

  onTimeRangeCreated(timeRange: TimeRange): void {
    this.rankingConfigs.timeRanges.push(timeRange);
  }

  formatTime(timeString: string): string {
    const timeMoment = moment(timeString, "HH:mm:ss");
    return moment(timeMoment).format("HH:mm");
  }

  async getRankingConfigs(): Promise<void> {
    const [
      rankingConfigs,
      tvNetworkConfigs,
    ] = await Promise.all([
      this.$store.dispatch("getRankingConfigs"),
      this.$store.dispatch('getTvNetworkConfigs'),
    ]);

    this.rankingConfigs = rankingConfigs;
    this.listOfAvailableTvNetworks = sortTvNetworks(tvNetworkConfigs.tvNetworks);
  }

  async updateRankingConfigs(): Promise<void> {
    if (!this.validateForm()) return;
    await this.$store.dispatch("updateRankingConfigs", this.rankingConfigs);
    this.$store.commit("showAlert", {
      message: "Alterações realizadas com sucesso!",
      type: "success",
    });
  }

  mounted(): void {
    this.getRankingConfigs();
  }
}
