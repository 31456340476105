
import { Component, Vue } from 'vue-property-decorator';
import TabContentWrapper from '@/components/TabContentWrapper.vue';
import draggable from 'vuedraggable';
import { TVNetworkConfigDTO } from '@/data/dto/config.dto';
import ModalFormHeader from '@/components/ModalFormHeader.vue';

@Component({
  components: {
    TabContentWrapper,
    draggable,
    ModalFormHeader,
  }
})
export default class TVNetworkConfigs extends Vue {
  tvNetworks: TVNetworkConfigDTO[] = [];
  modalIsVisible = false;
  confirmModalIsVisible = false;
  currentTvNetwork: TVNetworkConfigDTO | null = null;
  currentLabel = '';
  currentColor = '';
  currentTvNetworkId = '';
  currentTvNetworkIndex = 0;
  isPayTvNetwork = false;

  get isEdit(): boolean {
    return !!(this.currentTvNetwork?.tvNetworkId !== '');
  }
  get isValidForm(): boolean {
    if (this.isEdit) {
      return !!(this.currentLabel && this.currentTvNetworkId && this.currentTvNetworkId.length === 3);
    }
    else {
      return !!(this.currentLabel && this.currentTvNetworkId && this.currentTvNetworkId.length === 3);
    }
  }
  get modalTitle(): string {
    const currentTvNetworkId = this.currentTvNetwork ? this.currentTvNetwork.tvNetworkId : '';
    return `${this.isEdit ? `Edição de emissora - ${currentTvNetworkId}` : 'Adicionar emissora'}`;
  }

  editTvNetwork(tv: TVNetworkConfigDTO, currentTvNetworkIndex: number): void {
    this.currentTvNetwork = tv;
    this.currentTvNetworkId = tv.tvNetworkId;
    this.currentLabel = tv.exhibitionName;
    this.currentColor = tv.color || '#000000';
    this.isPayTvNetwork = tv.isPayTvNetwork;
    this.currentTvNetworkIndex = currentTvNetworkIndex;
    this.modalIsVisible = true;
  }
  addTvNetwork(): void {
    this.clearTvNetworksFields();

    this.currentTvNetwork = {
      tvNetworkId: this.currentTvNetworkId,
      exhibitionName: this.currentLabel,
      color: this.currentColor,
      isPayTvNetwork: this.isPayTvNetwork,
    } as TVNetworkConfigDTO;

    this.modalIsVisible = true;
  }
  removeTvNetwork(currentTvNetworkIndex: number): void {
    this.tvNetworks.splice(currentTvNetworkIndex, 1);
    this.closeConfirmModal();
    this.closeModal();
  }
  confirmChanges(): void {
    if (this.currentTvNetwork) {
      if (this.isEdit) {
        this.currentTvNetwork.tvNetworkId = this.currentTvNetworkId;
        this.currentTvNetwork.exhibitionName = this.currentLabel;
        this.currentTvNetwork.color = this.currentColor;
        this.currentTvNetwork.isPayTvNetwork = this.isPayTvNetwork;
      }
      else {
        const newTvNetwork = {
          exhibitionName: this.currentLabel,
          color: this.currentColor,
          isPayTvNetwork: this.isPayTvNetwork,
          tvNetworkId: this.currentTvNetworkId,
        } as TVNetworkConfigDTO;

        this.tvNetworks.push(newTvNetwork);
      }
    }

    this.closeModal();
  }
  closeModal(): void {
    this.clearTvNetworksFields();
    this.modalIsVisible = false;
  }
  openConfirmModal(): void {
    this.confirmModalIsVisible = true;
  }
  closeConfirmModal(): void {
    this.confirmModalIsVisible = false;
  }
  clearTvNetworksFields(): void {
    this.currentTvNetwork = null;
    this.currentLabel = '';
    this.currentColor = '#000000';
    this.currentTvNetworkId = '';
    this.isPayTvNetwork = false;
    this.currentTvNetworkIndex = 0;
  }
  async getTvNetworkConfigs(): Promise<void> {
    try {
      const { tvNetworks } = await this.$store.dispatch('getTvNetworkConfigs');
      this.tvNetworks = tvNetworks;
    } catch (e) {
      this.tvNetworks = [];
    }
  }

  async updateTvNetworkConfigs(): Promise<void> {
    try {
      await this.$store.dispatch('updateTvNetworkConfigs', { tvNetworks: this.tvNetworks });
      this.$store.commit('showAlert', { message: 'Alterações realizadas com sucesso!', type: 'success' });
    } catch (e) { return; }
  }

  mounted(): void {
    this.getTvNetworkConfigs();
  }
}
