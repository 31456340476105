
import { Component, Vue } from 'vue-property-decorator';
import RouteTitle from '@/components/RouteTitle.vue';
import General from '@/views/Admin/Configs/GeneralConfigs.vue';
import TvNetworks from '@/views/Admin/Configs/TvNetworkConfigs.vue';
import Markets from '@/views/Admin/Configs/MarketConfigs.vue';
import Notifications from '@/views/Admin/Configs/NotificationConfigs.vue';
import Ranking from'@/views/Admin/Configs/RankingConfigs.vue';
import DailyGrid from '@/views/Admin/Configs/DailyGridConfigs.vue';
import DailyAudience from '@/views/Admin/Configs/DailyAudienceConfigs.vue';
import MinuteByMinute from '@/views/Admin/Configs/MinuteByMinuteConfigs.vue';
import Consolidated from '@/views/Admin/Configs/ConsolidatedConfigs.vue';

@Component({
  components: {
    RouteTitle,
    General,
    TvNetworks,
    Markets,
    Notifications,
    DailyGrid,
    DailyAudience,
    MinuteByMinute,
    Consolidated,
    Ranking,
  },
})
export default class Configs extends Vue {}
