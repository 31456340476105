
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import draggable from 'vuedraggable';

@Component({
  components: {
    draggable,
  }
})
export default class DraggableTwoColumnsList extends Vue {
  @Prop({ default: ()=>[] }) private allItems!: string[];
  @Prop({ default: ()=>[] }) private selectedItems!: string[];
  @Prop(String) private allItemsLabel?: string;
  @Prop(String) private selectedItemsLabel?: string;

  availableItems: string[] = [];

  filterAvailableItems(): string[] {
    return this.allItems
      .filter(item => !(this.selectedItems.includes(item)));
  }

  @Watch('allItems')
  onAllItemsChanged(): void {
    this.availableItems = this.filterAvailableItems();
  }

  @Watch('selectedItems')
  onSelectedItemsChanged(): void {
    this.availableItems = this.filterAvailableItems();
  }

  selectItem(item: string): void {
    this.selectedItems.unshift(item);
  }

  deselectItem(index: number): void {
    this.selectedItems.splice(index, 1);
  }

  mounted(): void {
    this.availableItems = this.filterAvailableItems();
  }
}
