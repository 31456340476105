
import { Component, Watch, Vue } from 'vue-property-decorator';
import TabContentWrapper from '@/components/TabContentWrapper.vue';
import { UserDTO } from '@/data/dto/user.dto';
import { MarketConfigDTO } from '@/data/dto/config.dto';
import CustomSelectList from '@/components/CustomSelectList.vue';
import DraggableTwoColumnsList from '@/components/DraggableTwoColumnsList.vue';

@Component({
  components: {
    TabContentWrapper,
    CustomSelectList,
    DraggableTwoColumnsList,
  }
})
export default class NotificationConfigs extends Vue {
  currentUser: string | null = null;
  allUsers: UserDTO[] = [];
  
  allMarkets: string[] = [];
  marketsToNotify: string[] = [];

  async getUsers(): Promise<void> {
    try {
      const { users } = await this.$store.dispatch('getAllUsers');
      this.allUsers = users;
    } catch (e) {
      this.allUsers = [];
    }
  }

  @Watch('currentUser')
  onCurrentUserChanged(): void {
    this.getUserNotificationConfigs();
  }

  async getMarkets(): Promise<void> {
    try {
      const { markets } = await this.$store.dispatch('getMarketConfigs');
      this.allMarkets = markets.map((m: MarketConfigDTO) => m.marketId);
    } catch (e) {
      this.allMarkets = [];
    }
  }

  async getUserNotificationConfigs(): Promise<void> {
    try {
      const { markets } = await this.$store.dispatch('getNotificationConfigs', this.currentUser);
      this.marketsToNotify = markets;
    } catch (e) {
      this.marketsToNotify = [];
    }
  }

  async updateNotificationConfigs(): Promise<void> {  
    try {
      await this.$store.dispatch('updateNotificationConfigs', {
        user: this.currentUser,
        configs: { markets: this.marketsToNotify }
      });
      this.$store.commit('showAlert', { message: 'Alterações realizadas com sucesso!', type: 'success'});
    } catch (e) { return; }
  }

  async mounted(): Promise<void> {
    this.getUsers();
    this.getMarkets();
  }
}
