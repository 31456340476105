
import { Component, Vue, Watch } from 'vue-property-decorator';
import TabContentWrapper from '@/components/TabContentWrapper.vue';
import { GeneralConfigDTO, MarketConfigDTO, WeekdayConfigDTO, TVNetworkConfigDTO, TvNetworksByMarketConfigDTO } from '@/data/dto/config.dto';
import CustomGroupedCheckboxList from '@/components/CustomGroupedCheckboxList.vue';
import CustomSelectList from '@/components/CustomSelectList.vue';
import CreateWeekdaysGroupForm from '@/components/CreateWeekdaysGroupForm.vue';
import PillsList from '@/components/PillsList.vue';
import TvNetworkVisibilitySelection from '@/components/TvNetworkVisibilitySelection.vue';
import { sortTvNetworks } from "@/utils/tv-network.util";

@Component({
  components: {
    TabContentWrapper,
    CustomSelectList,
    CustomGroupedCheckboxList,
    CreateWeekdaysGroupForm,
    PillsList,
    TvNetworkVisibilitySelection,
  }
})
export default class GeneralConfigs extends Vue {
  generalConfigs: GeneralConfigDTO = {
    tvNetworksByMarketConfig: [],
    weekdaysConfig: []
  };

  allMarkets: MarketConfigDTO[] = [];
  allTvNetworks: TVNetworkConfigDTO[] = [];

  selectedMarket: MarketConfigDTO | null = null;
  currentMarketTvNetworkListConfig: TvNetworksByMarketConfigDTO | null = null;

  get marketsGroupedByType(): Record<string, any>[] {
    const list = this.allMarkets;
    return [
      {
        title: 'Regular',
        collection: list.filter((market) => market.isRegularMarket),
      },
      {
        title: 'Caderno',
        collection: list.filter((market) => !market.isRegularMarket),
      },
    ];
  }

  @Watch('selectedMarket')
  onCurrentMarketChange(selectedMarket: MarketConfigDTO) {
    this.currentMarketTvNetworkListConfig = this.generalConfigs.tvNetworksByMarketConfig
      .find(m => m.marketId === selectedMarket.marketId) || null;
  }

  onWeekdayGroupCreated(weekdayGroup: WeekdayConfigDTO): void {
    this.generalConfigs.weekdaysConfig.push(weekdayGroup);
  }

  async getData(): Promise<void> {
    try {
      const [generalConfigs, marketconfigs, tvNetworksconfigs] = await Promise.all([
        this.$store.dispatch('getGeneralConfigs'),
        this.$store.dispatch('getMarketConfigs'),
        this.$store.dispatch('getTvNetworkConfigs'),
      ]);
      this.generalConfigs = generalConfigs;
      this.allMarkets = marketconfigs.markets;
      this.allTvNetworks = sortTvNetworks(tvNetworksconfigs.tvNetworks);
    } catch(e) {
      this.generalConfigs = { tvNetworksByMarketConfig: [], weekdaysConfig: [] };
      this.allMarkets = [];
      this.allTvNetworks = [];
    }
  }

  async updateGeneralConfigs(): Promise<void> {
    try {
      await this.$store.dispatch('updateGeneralConfigs', this.generalConfigs);
      this.$store.commit('showAlert', { message: 'Alterações realizadas com sucesso!', type: 'success'});
    } catch (e) { return; }
  }

  async mounted(): Promise<void> {
    await this.getData();
  }
}
