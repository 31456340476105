
import { Component, Mixins } from 'vue-property-decorator';
import { MarketGroup } from '@/data/dto/config.dto';
import FormValidationMixin, { IFormValidations } from '@/mixins/form-validation.mixin';

@Component({})
export default class MarketGroupForm extends Mixins(FormValidationMixin) {  
  private defaultMarketGroup: MarketGroup = {
    label: '',
    markets: []
  };
  marketGroup = {...this.defaultMarketGroup};

  
  formValidations: IFormValidations = {
    label: () => !!this.marketGroup.label,
  };

  createMarketGroup(): void {
    if (!this.validateForm()) return;
    this.$emit('marketGroupCreated', this.marketGroup);
    this.marketGroup = {...this.defaultMarketGroup};
  }
}
