
import { Component, Mixins } from 'vue-property-decorator';
import TabContentWrapper from '@/components/TabContentWrapper.vue';
import { MinuteByMinuteConfigDTO } from '@/data/dto/config.dto';
import FormValidationMixin, { IFormValidations } from '@/mixins/form-validation.mixin';
import VUtil from '@/utils/validation.util';

@Component({
  components: {
    TabContentWrapper,
  }
})
export default class MinuteByMinuteConfigs extends Mixins(FormValidationMixin) {
  //default value
  defaultMinuteByMinuteConfigs: MinuteByMinuteConfigDTO = {
    decimalPlaces: 0,
    timeRanges: {
      allDay: {
        start: '00:00:00',
        end: '00:00:00',
      },
      morning: {
        start: '00:00:00',
        end: '00:00:00'
      },
      afternoon:{
        start: '00:00:00',
        end: '00:00:00',
      },
      night:{
        start: '00:00:00',
        end: '00:00:00',
      },
      dawn: {
        start: '00:00:00',
        end: '00:00:00',
      },
    },
    comparisonColors: {
      better: '#0000ff',
      worse: '#ff0000',
      equal: '#bbbbbb',
    },    
    receivementMinutes: 0,
    deliveryMinutes: 0,
  };
  minuteByMinuteConfigs: MinuteByMinuteConfigDTO = {...this.defaultMinuteByMinuteConfigs}

  timeRangesLabels = {
    allDay: 'Dia todo',
    morning: 'Manhã',
    afternoon: 'Tarde',
    night: 'Noite',
    dawn: 'Madrugada',
  };

  comparisonColorsLabels = {
    better: 'Melhor',
    worse: 'Pior',
    equal: 'Igual',
  };

  //validations
  formValidations: IFormValidations = {
    decimalPlaces: this.checkDecimalPlaces,
    receivementMinutes: this.checkReceivementMinutes,
    deliveryMinutes: this.checkDeliveryMinutes,
  };
  checkDecimalPlaces(): boolean {
    return VUtil.isNumber(this.minuteByMinuteConfigs.decimalPlaces);
  }
  checkReceivementMinutes(): boolean {
    return VUtil.isNumber(this.minuteByMinuteConfigs.receivementMinutes);
  }
  checkDeliveryMinutes(): boolean {
    return VUtil.isNumber(this.minuteByMinuteConfigs.deliveryMinutes);
  }
  
  async getMinuteByMinuteConfigs(): Promise<void> {
    try {
      this.minuteByMinuteConfigs = await this.$store.dispatch('getMinuteByMinuteConfigs');
    } catch (e) {
      this.minuteByMinuteConfigs = {...this.defaultMinuteByMinuteConfigs};
    }
  }

  async updateMinuteByMinuteConfigs(): Promise<void> {
    if (!this.validateForm()) return; 
    try {
      await this.$store.dispatch('updateMinuteByMinuteConfigs', this.minuteByMinuteConfigs);
      this.$store.commit('showAlert', { message: 'Alterações realizadas com sucesso!', type: 'success'});
    } catch (e) { return; }
  }

  mounted(): void {
    this.getMinuteByMinuteConfigs();
  }
    
}
