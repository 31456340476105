
import { Component, Prop, Mixins } from 'vue-property-decorator';
import { TimeRange } from '@/data/dto/time-range.dto';
import FormValidationMixin, { IFormValidations } from '@/mixins/form-validation.mixin';

@Component({})
export default class CreateTimeRange extends Mixins(FormValidationMixin) {
  @Prop({ default: false }) private hasLabel!: boolean;
  
  private defaultTimeRange: TimeRange = {
    label: '',
    start: '00:00:00',
    end: '00:00:00'
  };
  timeRange = {...this.defaultTimeRange};

  
  formValidations: IFormValidations = {
    label: () => !this.hasLabel || !!this.timeRange.label,
  };

  createTimeRange(): void {
    if (!this.validateForm()) return;
    if (!this.hasLabel) delete this.timeRange.label;
    this.$emit('timeRangeCreated', this.timeRange);
    this.timeRange = {...this.defaultTimeRange};
  }
}
